/* Static font imports start*/
@font-face {
  /* font-family: "Lato", sans-serif;
  src: url(../assets/fonts/OFL.txt); */
  /* font-family: "Fira Code", monospace;
  font-family: "Lato", sans-serif; */
}
body {
  font-family: "Fira Code", monospace;
  font-family: "Lato", sans-serif;
}
/* This folder will contains all the css files and global styles if used */
.error_checkbox svg {
  border: 2px solid #d32f2f !important;
}

.main-content-container {
  min-height: calc(100vh - 125px);
  box-sizing: border-box;
  /* max-width: 1700px; */
  margin-left: auto;
  margin-right: auto;
}
.Toastify__toast-container {
  min-width: 450px;
}
/* .max-width-app {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
} */
.header-img {
  background-image: url(../assets/images/headerbg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
}

.MuiDivider-wrapper {
  background-color: transparent;
  width: 100px;
}
/* This is class of modal */
/* changed actual border radius */
.css-7yntvo-MuiPaper-root-MuiDialog-paper {
  /* border-radius: 4px; */
  border-radius: 16px;
}

.MuiRadio-root {
  padding: 0px;
}

input[type="radio"]:checked {
  background-color: green;
}

::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #2d794d; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Round the corners of the scrollbar thumb */
  max-height: "50px";
}

.cursor-pointer {
  cursor: pointer;
}

.message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 74%;
  position: relative;
}

.admin-message {
  background: #286c45;
  text-align: left;
  float: left;
}

p.message-meta {
  font-size: 13px;
  text-align: right;
}
.message.user-message {
  color: black;
  background: #ccc;
  float: right;
  text-align: left;
}
.loader-box {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 45px;
}
.autocomplete__input .MuiOutlinedInput-root {
  padding: 4px;
}
.Mui-error.MuiFormHelperText-contained {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.fw600 {
  font-weight: 600 !important;
}
@media (max-width: 426px) {
  .MuiDialog-paper {
    margin: 15px !important;
  }
  input[type="tel"] {
    margin-right: 15px !important;
  }
}

@media (max-width: 376px) {
  input[type="tel"] {
    margin-right: 10px !important;
  }
}

@media (max-width: 320px) {
  input[type="tel"] {
    margin-right: 5px !important;
  }
}
